@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


  
  .slick-slide > div {
    margin: 0 10px;
  }

  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }
  
  .custom-arrow-next {
    right: 10px;
  }
  
  .custom-arrow-prev {
    left: 10px;
  }

  .card {
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .shadow-lg {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  .rounded-lg {
    border-radius: 12px;
  }


  
  
  